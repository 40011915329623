// import { useCallback } from "react";
import { useMemo } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { authSelector } from "redux/auth/selector";
// import { sessionsSelector } from "redux/session/selector";

const useRole: any = (role: string[]) => {
  const profile = useSelector(
    (state) => authSelector(state).profile,
    shallowEqual
  );

  return useMemo(() => role.includes(profile.role), [profile.role, role]);
};

export default useRole;
