import React, { forwardRef, useCallback } from "react";
import { useSnackbar, SnackbarContent, VariantType } from "notistack";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import { Box } from "@mui/material";
import { AppConfig } from "enums/config";

interface CustomAlertProps {
  id: number;
  variant: VariantType |string,
  message: string;
  theme:any
}
const CustomAlert = forwardRef(
  ({ id, variant = "general", message,theme }: CustomAlertProps, ref: any) => {
    const { closeSnackbar } = useSnackbar();

    const handleDismiss = useCallback(() => {
      closeSnackbar(id);
    }, [id, closeSnackbar]);

    const variantArr: any = {
      success: {
        icon: CheckIcon,
        color: theme.palette.general.successAlert,
        boxShadow: theme.palette.general.successShadowAlert,
      },
      error: {
        icon: ErrorIcon,
        color: theme.palette.general.errorAlert,
        boxShadow: theme.palette.general.errorShadowAlert,
      },
      info: {
        icon: InfoIcon,
        color: theme.palette.general.generalAlert,
        boxShadow: theme.palette.general.generalShadowAlert,
      },
      warning: {
        icon: WarningIcon,
        color: theme.palette.general.generalAlert,
        boxShadow: theme.palette.general.generalShadowAlert,
      },
      general: {
        icon: null,
        color: theme.palette.general.generalAlert,
        boxShadow: theme.palette.general.generalShadowAlert,
      },
    };

    const IconAlert = variantArr[variant]?.icon;
    return (
      <SnackbarContent ref={ref}>
        <Card
          sx={{
            backgroundColor: theme.palette.general.white,
            width: "100%",
            "@media (min-width:600px)": {
              minWidth: "333px !important",
            },
            borderRadius: AppConfig.BORDER_RADIUS_MIN,
            boxShadow: variantArr[variant]?.boxShadow || "none",
          }}
        >
          <CardActions
            sx={{
              padding: "8px 8px 8px 16px",
              justifyContent: "space-between",
            }}
          >
            {IconAlert && (
              <IconAlert
                sx={{
                  fill:
                    variantArr[variant]?.color ||
                    theme.palette.general.generalAlert,
                }}
              />
            )}
            <Typography
              variant="subtitle2"
              sx={{
                color:
                  variantArr[variant]?.color ||
                  theme.palette.general.generalAlert,
                px: "8px",
                fontSize: "16px",
                fontFamily: "Roboto",
              }}
            >
              {message}
            </Typography>
            <Box sx={{ marginLeft: "auto" }}>
              <IconButton
                sx={{
                  padding: "8px 8px",
                  transform: "rotate(0deg)",
                  transition: "all .2s",
                }}
                onClick={handleDismiss}
              >
                <CloseIcon
                  sx={{
                    fill:
                      variantArr[variant]?.color ||
                      theme.palette.general.generalAlert,
                  }}
                />
              </IconButton>
            </Box>
          </CardActions>
        </Card>
      </SnackbarContent>
    );
  }
);

export default CustomAlert;
