export const SELECT_COMMUNITY = "SELECT_COMMUNITY";
export const SEARCH_COMMUNITY = "SEARCH_COMMUNITY";
export const GET_COMMUNITY_CHANNELS = "GET_COMMUNITY_CHANNELS";
export const UPDATE_COMMUNITY_CHANNELS = "UPDATE_COMMUNITY_CHANNELS";
export const CREATE_COMMUNITY_CHANNEL = "CREATE_COMMUNITY_CHANNEL";
export const DELETE_COMMUNITY_CHANNEL = "DELETE_COMMUNITY_CHANNEL";
export const GET_COMMUNITY_GROUPS = "GET_COMMUNITY_GROUPS";
export const GET_COMMUNITY_DIRECTS = "GET_COMMUNITY_DIRECTS";
export const GET_COMMUNITY_MEMBERS = "GET_COMMUNITY_MEMBERS";
export const INVITE_MEMBER_CHANNEL = "INVITE_MEMBER_CHANNEL";
export const REMOVE_MEMBER_CHANNEL = "REMOVE_MEMBER_CHANNEL";
export const UPDATE_MEMBER_VISIBILITY = "UPDATE_MEMBER_VISIBILITY";
export const CHANGE_MEMBER_ROLE_CHANNEL = "CHANGE_MEMBER_ROLE_CHANNEL";
export const SEND_COMMUNITY_MESSAGE = "SEND_COMMUNITY_MESSAGE";
export const SEND_COMMUNITY_MESSAGE_CONFIRMATION =
  "SEND_COMMUNITY_MESSAGE_CONFIRMATION";
export const COMMUNITY_NEW_MESSAGE_NOTIFICATION =
  "COMMUNITY_NEW_MESSAGE_NOTIFICATION";
export const RESET_NEW_MESSAGE_NOTIFICATION = "RESET_NEW_MESSAGE_NOTIFICATION";
export const GET_COMMUNITY_MESSAGES = "GET_COMMUNITY_MESSAGES";
export const SEND_MSG_ACKNOWLEDGMENT = "SEND_MSG_ACKNOWLGMENT";
export const READ_MESSAGES = "READ_MESSAGES";
export const COMMUNITY_MESSAGES_COMPLETE = "COMMUNITY_MESSAGES_COMPLETE";
export const COMMUNITY_OFFSET = "COMMUNITY_OFFSET";
export const GET_MSG_RECIPIENTS = "GET_MSG_RECIPIENTS";
