import axios from "axios";
import http from "../config/httpComman";

const URL = "/media/upload";
export default class mediaService {
  static async uploadMedia(data: any) {
    return await http.post(URL, data);
  }
  static async downloadMedia(url: string, ...args: any[]) {
    return await axios.get(url, ...args);
  }
}
