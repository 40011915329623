import {
  TRIGGER_ALERT,
  REMOVE_ALERT,
  LOADING,
  STOP_LOADING,
  SET_NAV_HEIGHT,
  APP_UPDATED,
  LOADING_MEDIA
} from "./types";

const initialState: any = {
  trigger: false,
  loading: false,
  navHeight: 0,
  duration: 1500,
  appUpdated: false,
  loadingMedia:false

};

export default function alertReducer(
  state = initialState,
  action: { type: any; payload: any }
) {
  const { type, payload } = action;
  switch (type) {
    case TRIGGER_ALERT:
      return {
        ...state,
        ...payload,
        trigger: true,
        loading: false,
        duration: 1500,
      };
    case REMOVE_ALERT:
      return { ...state, trigger: false, loading: false, duration: payload };
    case LOADING:
      return { ...state, loading: true };
    case STOP_LOADING:
      return { ...state, loading: false };
    case SET_NAV_HEIGHT:
      return { ...state, navHeight: payload };
    case APP_UPDATED:
      return { ...state, appUpdated: payload };
    case LOADING_MEDIA:
      return {
        ...state,
        loadingMedia: payload,
      };
    default:
      return state;
  }
}
