import { HttpRequestUserSpaceData } from "components/flow/type";
import { typeVariable } from "enums/flow";
import {
  filter,
  findIndex,
  has,
  includes,
  isEmpty,
  isEqual,
  keys,
  map,
  mapKeys,
  merge,
  mergeWith,
  reduce,
  size,
  uniq,
} from "lodash";
import { Node } from "react-flow-renderer";
import { flowData, subFlowsUsageData } from "./type";
import {
  ADD_ERRORS_RELATED_VARIABLES,
  ADD_FIELDS_ERRORS_REPLY,
  ADD_HTTP_REQUEST_USER_SPACE,
  ADD_NODES_CONNECTED_JUMP,
  ADD_NODES_CONNECTED_SUB_FLOW,
  ADD_NODES_CONNECTED_VARS,
  ADD_NODES_FIELDS_ERRORS,
  ADD_SUB_FLOW,
  CUSTOM_LOADING,
  CUSTOM_STOP_LOADING,
  DELETE_ERRORS_RELATED_VARIABLES,
  DELETE_NODES_CONNECTED_SUB_FLOW,
  DELETE_NODES_FIELDS_ERRORS,
  DELETE_RULE_UPDATE_CONNECTED_VARS,
  DELETE_SUB_FLOW,
  DISPLAY_ERROR_MODAL,
  DISPLAY_TEMPLATE_MODAL,
  EDIT_SUB_FLOW,
  ERRORS_RELATED_VARIABLES,
  FETCH_SUB_FLOWS,
  FLOW_CLEAR,
  FLOW_CREATE,
  FLOW_DELETE,
  FLOW_FETCH,
  FLOW_FILTER_TRIGGER,
  FLOW_SEARCH,
  FLOW_STATUS,
  FLOW_UPDATE,
  FLOW_UPDATE_STATUS,
  GET_HTTP_REQUEST_USER_SPACE,
  GET_ORDERED_NODES,
  NODES_CONNECTED_JUMP,
  NODES_CONNECTED_SUB_FLOW,
  NODES_CONNECTED_VARS,
  NODES_DELETE_CONNECTED_JUMP,
  NODES_DELETE_GROUP_CONNECTED_JUMP,
  NODES_FIELDS_ERRORS,
  NODES_GET,
  NODES_NOTIFIED,
  NODES_UPDATE,
  NODES_UPDATE_CONNECTED_JUMP,
  NODES_UPDATE_CONNECTED_VARS,
  STOP_DISPLAY_ERROR_MODAL,
  STOP_DISPLAY_TEMPLATE_MODAL,
  UPDATE_ALL_VARIABLES,
  UPDATE_FLOW_OPTIONS,
  UPDATE_HTTP_REQUEST_USER_SPACE,
  UPDATE_NODES_CONNECTED_SUB_FLOW,
  UPDATE_NODES_FIELDS_ERRORS,
  UPDATE_NODES_FIELDS_ERRORS_REPLY,
  UPDATE_ORDERED_NODES,
  UPDATE_ORDERED_NODES_SUB_FLOWS,
  UPDATE_SELECTED_CONDITION,
  UPDATE_SHOW_DRAWER,
} from "./types";
import {
  findDifferences,
  handleErrorsRelatedVaraiblesWithCopyNodes,
  mergeCustomizer,
} from "./utils";

interface IntialState {
  list: flowData[];
  originalList: flowData[];
  currentFlow: flowData | null;
  subFlows: any;
  nodesConnectedWithSubFlows: any;
  customObjNodes: any;
  nodesConnectedWithSystemVars: Record<string, any>;
  notifiedNodes: any;
  nodesConnectedWithJump: Record<string, any>;
  httpRequestUserSpace: Record<string, HttpRequestUserSpaceData>;
  showDrawer: { enable: boolean; node: Node };
  selectedCondition: string;
  errorsRelatedVariables: any;
  errorsFieldsNodes: {
    [key in string]: { [key in string]: string };
  };
  currentFlowOptions: {
    isProcessed: boolean;
    isUsedEndCondition: boolean;
  } | null;
  orderedNodesObj: null | {
    [nodeId: string]: {
      index: number;
      parentId: number;
      subFlowNodesId?: {
        [nodeId: string]: {
          index: number;
          parentId: number;
        };
      };
    };
  };
  allVariables: any;
  customLodaer: boolean;
  isDisplayErrorModal: boolean;
  isDisplayTemplateModal: boolean;
  isEnableUpdateNodesConnectedWithVars: boolean;
}

const initialState: IntialState = {
  list: [],
  originalList: [],
  currentFlow: null,
  subFlows: null,
  nodesConnectedWithSubFlows: {},
  customObjNodes: {},
  nodesConnectedWithSystemVars: {}, // {varId:{nodeId:[nodeId or ruleid]}}
  notifiedNodes: [],
  nodesConnectedWithJump: {},
  httpRequestUserSpace: {},
  showDrawer: { enable: false, node: {} as Node },
  selectedCondition: "",
  errorsRelatedVariables: {},
  errorsFieldsNodes: {},
  currentFlowOptions: null,
  orderedNodesObj: null,
  allVariables: {},
  customLodaer: false,
  isDisplayErrorModal: false,
  isDisplayTemplateModal: false,
  isEnableUpdateNodesConnectedWithVars: false,
};

export default function authReducer(
  state = initialState,
  action: { type: any; payload: any }
) {
  const { type, payload } = action;
  switch (type) {
    case FLOW_STATUS: {
      const sortedList = (payload?.list || []).sort(
        (a: flowData, b: flowData) => {
          const dateA = new Date(a?.CreatedAt);
          const dateB = new Date(b?.CreatedAt);
          // Sort in descending order (newest to oldest)
          return dateB.getTime() - dateA.getTime();
        }
      );
      return {
        ...state,
        list: sortedList,
        originalList: payload.list,
      };
    }
    case FLOW_SEARCH: {
      return {
        ...state,
        list: filter([...state?.originalList], (item) =>
          item.Name.toLowerCase().includes(payload.toLowerCase())
        ),
      };
    }
    case FLOW_FILTER_TRIGGER: {
      let res = [];
      if (payload.length === 0) {
        res = [...state?.originalList];
      } else {
        res = filter([...state?.originalList], (item) =>
          payload.includes(item?.Trigger?.split("-")[0])
        );
      }
      return {
        ...state,
        list: res,
      };
    }
    case FLOW_CREATE: {
      return {
        ...state,
        list: [payload, ...state?.list],
      };
    }
    case FLOW_FETCH: {
      return {
        ...state,
        currentFlow: payload,
        isRefreshDuplicatedHttpVars: false,
      };
    }
    case FLOW_DELETE: {
      return {
        ...state,
        list: filter(state.list, (item: flowData) => item?.Key !== payload),
      };
    }
    case FLOW_UPDATE: {
      return {
        ...state,
        list: [...state.list].map((item: flowData) =>
          item.Key === payload?.Key ? Object.assign({}, payload, {}) : item
        ),
      };
    }
    case FLOW_UPDATE_STATUS: {
      return {
        ...state,
        currentFlow: payload,
      };
    }
    case FETCH_SUB_FLOWS: {
      return {
        ...state,
        subFlows: reduce(
          payload,
          (subFlowObj: any, param: any) => {
            subFlowObj[param.id] = param;
            return subFlowObj;
          },
          {}
        ),
      };
    }
    case DELETE_SUB_FLOW: {
      let resOrderedNodesObj = { ...state.orderedNodesObj };
      delete resOrderedNodesObj?.[payload];
      let subFlows = { ...state.subFlows };
      delete subFlows?.[payload];
      let resNodesConnectedWithSubFlows: {
        [subFlowId: string]: subFlowsUsageData;
      } = { ...state.nodesConnectedWithSubFlows };
      map(
        resNodesConnectedWithSubFlows?.[payload]?.callee,
        (flowId: string) => {
          resNodesConnectedWithSubFlows[flowId] = {
            ...resNodesConnectedWithSubFlows[flowId],
            caller: filter(
              resNodesConnectedWithSubFlows[flowId]?.caller,
              (i) => i !== payload
            ),
          };
        }
      );
      delete resNodesConnectedWithSubFlows[payload];
      return {
        ...state,
        subFlows,
        nodesConnectedWithSubFlows: resNodesConnectedWithSubFlows,
        orderedNodesObj: resOrderedNodesObj,
      };
    }
    case ADD_SUB_FLOW: {
      return {
        ...state,
        subFlows: {
          ...state.subFlows,
          ...payload,
        },
      };
    }
    case EDIT_SUB_FLOW: {
      return {
        ...state,
        subFlows: {
          ...state.subFlows,
          [payload?.id]: { ...payload },
        },
      };
    }
    case FLOW_CLEAR: {
      return {
        ...state,
        currentFlow: null,
        subFlows: null,
        currentFlowOptions: null,
        selectedCondition: "",
        errorsRelatedVariables: {},
        errorsFieldsNodes: {},
        orderedNodesObj: null,
        allVariables: {},
        customLodaer: false,
        isDisplayErrorModal: false,
        customObjNodes: {},
        nodesConnectedWithSystemVars: {}, // {varId:{nodeId:[nodeId or ruleid]}}
        notifiedNodes: [],
        nodesConnectedWithJump: {},
        nodesConnectedWithSubFlows: {},
        httpRequestUserSpace: {},
        showDrawer: { enable: false, node: {} as Node },
      };
    }
    case NODES_GET: {
      return {
        ...state,
        customObjNodes: payload,
      };
    }
    case ERRORS_RELATED_VARIABLES: {
      return {
        ...state,
        errorsRelatedVariables: payload,
      };
    }
    case ADD_ERRORS_RELATED_VARIABLES: {
      return {
        ...state,
        errorsRelatedVariables: { ...state.errorsRelatedVariables, ...payload },
      };
    }
    case DELETE_ERRORS_RELATED_VARIABLES: {
      let res = { ...state.errorsRelatedVariables };
      map(payload, (item) => {
        delete res?.[item];
      });
      return {
        ...state,
        errorsRelatedVariables: { ...res },
      };
    }
    case NODES_UPDATE: {
      // let id: number = payload?.id || 0
      // let targetNode={...state.customObjNodes[id]}
      // delete targetNode?.data
      // targetNode["data"]=payload?.data
      let id: number = payload?.id || 0;
      return {
        ...state,
        customObjNodes: {
          ...state.customObjNodes,
          [id]: { ...state.customObjNodes[id], data: payload?.data },
        },
      };
    }
    /** system variables */
    case NODES_CONNECTED_VARS: {
      return {
        ...state,
        nodesConnectedWithSystemVars: payload,
      };
    }
    case ADD_NODES_CONNECTED_VARS: {
      return {
        ...state,
        nodesConnectedWithSystemVars: merge(
          {},
          state.nodesConnectedWithSystemVars,
          payload
        ),
      };
    }
    case NODES_UPDATE_CONNECTED_VARS: {
      let userSpace = { ...state?.nodesConnectedWithSystemVars };
      let newVariableTitle = ""
      let prevVaraibleTitle = ""
      if (!isEmpty(payload?.newValue)) {
        if (
          payload?.newValue?.type === typeVariable.ARRAY ||
          payload?.newValue?.type === typeVariable.OBJECT
        ) {
          userSpace = {
            ...userSpace,
            [payload?.newValue?.title]: {
              ...(userSpace?.[payload?.newValue?.title] || {}),
              [payload?.newValue?.type]: {
                ...(userSpace?.[payload?.newValue?.title]?.[
                  payload?.newValue?.type
                ] || {}),
                [JSON.stringify(payload?.newValue?.arrKeys)]: {
                  ...(userSpace?.[payload?.newValue?.title]?.[
                    payload?.newValue?.type
                  ]?.[JSON.stringify(payload?.newValue?.arrKeys)] || {}),
                  [payload?.nodeConditionId]: [
                    ...(userSpace?.[payload?.newValue?.title]?.[
                      payload?.newValue?.type
                    ]?.[JSON.stringify(payload?.newValue?.arrKeys)]?.[
                      payload?.nodeConditionId
                    ] || []),
                    payload?.ruleId,
                  ],
                },
              },
            },
          };

        } else {
          newVariableTitle = payload?.newValue?.orginalTitle || payload?.newValue?.title

          userSpace = {
            ...userSpace,
            [newVariableTitle]: {
              ...(userSpace?.[newVariableTitle] || {}),
              [payload?.newValue?.type]: {
                ...(userSpace?.[newVariableTitle]?.[
                  payload?.newValue?.type
                ] || {}),
                [payload?.nodeConditionId]: [
                  ...(userSpace?.[newVariableTitle]?.[
                    payload?.newValue?.type
                  ]?.[payload?.nodeConditionId] || []),
                  payload?.ruleId,
                ],
              },
            },
          };
          if (payload?.newValue?.isUsedVarsAsIndexArr) {
            let newindexsVars = findDifferences(payload?.newValue?.indexsVars || {}, payload?.prevValue?.indexsVars || {})
            // difference(Object.keys(payload?.newValue?.indexsVars || {}), Object.keys(payload?.prevValue?.indexsVars || {}))
            mapKeys(newindexsVars, (indexVar) => {
              userSpace = {
                ...userSpace,
                [indexVar?.title]: {
                  ...(userSpace?.[indexVar?.title] || {}),
                  [indexVar?.type]: {
                    ...(userSpace?.[indexVar?.title]?.[
                      indexVar?.type
                    ] || {}),
                    [payload?.nodeConditionId]: [
                      ...(userSpace?.[indexVar?.title]?.[
                        indexVar?.type
                      ]?.[payload?.nodeConditionId] || []),
                      payload?.ruleId,
                    ],
                  },
                },
              };
            })
          }
        }
      }
      if (!isEmpty(payload?.prevValue)) {
        if (
          payload?.prevValue?.type === typeVariable.ARRAY ||
          payload?.prevValue?.type === typeVariable.OBJECT
        ) {
          let ruleIdsArr = filter(
            [
              ...userSpace?.[payload?.prevValue?.title]?.[
              payload?.prevValue?.type
              ]?.[JSON.stringify(payload?.prevValue?.arrKeys)]?.[
              payload?.nodeConditionId
              ],
            ],
            (item) => item !== payload?.ruleId
          );
          if (ruleIdsArr?.length > 0) {
            userSpace = {
              ...userSpace,
              [payload?.prevValue?.title]: {
                ...(userSpace?.[payload?.prevValue?.title] || {}),
                [payload?.prevValue?.type]: {
                  ...(userSpace?.[payload?.prevValue?.title]?.[
                    payload?.prevValue?.type
                  ] || {}),
                  [JSON.stringify(payload?.prevValue?.arrKeys)]: {
                    ...(userSpace?.[payload?.prevValue?.title]?.[
                      payload?.prevValue?.type
                    ]?.[JSON.stringify(payload?.prevValue?.arrKeys)] || {}),
                    [payload?.nodeConditionId]: ruleIdsArr,
                  },
                },
              },
            };
          } else {
            delete userSpace?.[payload?.prevValue?.title]?.[
              payload?.prevValue?.type
            ]?.[JSON.stringify(payload?.prevValue?.arrKeys)]?.[
              payload?.nodeConditionId
            ];
            if (
              size(
                userSpace?.[payload?.prevValue?.title]?.[
                payload?.prevValue?.type
                ]?.[JSON.stringify(payload?.prevValue?.arrKeys)]
              ) === 0
            ) {
              delete userSpace?.[payload?.prevValue?.title]?.[
                payload?.prevValue?.type
              ]?.[JSON.stringify(payload?.prevValue?.arrKeys)];
              if (
                size(
                  userSpace?.[payload?.prevValue?.title]?.[
                  payload?.prevValue?.type
                  ]
                ) === 0
              ) {
                delete userSpace?.[payload?.prevValue?.title]?.[
                  payload?.prevValue?.type
                ];
              }
              if (size(userSpace?.[payload?.prevValue?.title]) === 0) {
                delete userSpace?.[payload?.prevValue?.title];
              }
            }
          }
        } else {
          prevVaraibleTitle = payload?.prevValue?.orginalTitle || payload?.prevValue?.title
          if (!isEqual(prevVaraibleTitle, newVariableTitle)) {
            let ruleIdsArr = filter(
              [
                ...(userSpace?.[prevVaraibleTitle]?.[
                  payload?.prevValue?.type
                ]?.[payload?.nodeConditionId] || []),
              ],
              (item) => item !== payload?.ruleId
            );
            if (ruleIdsArr?.length > 0) {
              userSpace = {
                ...userSpace,
                [prevVaraibleTitle]: {
                  ...(userSpace?.[prevVaraibleTitle] || {}),
                  [payload?.prevValue?.type]: {
                    ...(userSpace?.[prevVaraibleTitle]?.[
                      payload?.prevValue?.type
                    ] || {}),
                    [payload?.nodeConditionId]: ruleIdsArr,
                  },
                },
              };
            } else {
              delete userSpace?.[prevVaraibleTitle]?.[
                payload?.prevValue?.type
              ]?.[payload?.nodeConditionId];
              if (
                size(
                  userSpace?.[prevVaraibleTitle]?.[
                  payload?.prevValue?.type
                  ]
                ) === 0
              ) {
                delete userSpace?.[prevVaraibleTitle]?.[
                  payload?.prevValue?.type
                ];
                if (size(userSpace?.[prevVaraibleTitle]) === 0) {
                  delete userSpace?.[prevVaraibleTitle];
                }
              }
            }
          }
          if (payload?.prevValue?.isUsedVarsAsIndexArr) {
            let prevIndexsVars = findDifferences(payload?.prevValue?.indexsVars, payload?.newValue?.indexsVars)
            // let prevIndexsVars = difference(Object.keys(payload?.?.indexsVars || {}), Object.keys(payload?.newValue?.indexsVars || {}))
            mapKeys(prevIndexsVars, (indexVar) => {
              let ruleIdsArr = filter(
                [
                  ...(userSpace?.[indexVar?.title]?.[
                    indexVar?.type
                  ]?.[payload?.nodeConditionId] || []),
                ],
                (item) => item !== payload?.ruleId
              );
              if (ruleIdsArr?.length > 0) {
                userSpace = {
                  ...userSpace,
                  [indexVar?.title]: {
                    ...(userSpace?.[indexVar?.title] || {}),
                    [indexVar?.type]: {
                      ...(userSpace?.[indexVar?.title]?.[
                        indexVar?.type
                      ] || {}),
                      [payload?.nodeConditionId]: ruleIdsArr,
                    },
                  },
                };
              } else {
                delete userSpace?.[indexVar?.title]?.[
                  indexVar?.type
                ]?.[payload?.nodeConditionId];
                if (
                  size(
                    userSpace?.[indexVar?.title]?.[
                    indexVar?.type
                    ]
                  ) === 0
                ) {
                  delete userSpace?.[indexVar?.title]?.[
                    indexVar?.type
                  ];
                  if (size(userSpace?.[indexVar?.title]) === 0) {
                    delete userSpace?.[indexVar?.title];
                  }
                }
              }
            })
          }
        }
      }
      return {
        ...state,
        nodesConnectedWithSystemVars: userSpace,
      };
    }

    case DELETE_RULE_UPDATE_CONNECTED_VARS: {
      let userSpace = { ...state?.nodesConnectedWithSystemVars };
      if (payload?.nodeConditionId && payload?.ruleId) {
        if (payload?.systemVarTitle) {
          let ruleIdsArr = filter(
            [
              ...userSpace?.[payload?.systemVarTitle]?.[
              payload?.systemVarType
              ]?.[payload?.nodeConditionId],
            ],
            (item) => item !== payload?.ruleId
          );
          if (ruleIdsArr?.length > 0) {
            userSpace = {
              ...userSpace,
              [payload?.systemVarTitle]: {
                ...(userSpace?.[payload?.systemVarTitle] || {}),
                [payload?.systemVarType]: {
                  ...(userSpace?.[payload?.systemVarTitle]?.[
                    payload?.systemVarType
                  ] || {}),
                  [payload?.nodeConditionId]: ruleIdsArr,
                },
              },
            };
          } else {
            delete userSpace?.[payload?.systemVarTitle]?.[
              payload?.systemVarType
            ]?.[payload?.nodeConditionId];
            if (
              size(
                userSpace?.[payload?.systemVarTitle]?.[payload?.systemVarType]
              ) === 0
            ) {
              delete userSpace?.[payload?.systemVarTitle]?.[
                payload?.systemVarType
              ];
              if (size(userSpace?.[payload?.systemVarTitle]) === 0) {
                delete userSpace?.[payload?.systemVarTitle];
              }
            }
          }
        }
      }
      return {
        ...state,
        nodesConnectedWithSystemVars: userSpace,
      };
    }
    /**relations nodes with jump nodes*/
    case NODES_CONNECTED_JUMP: {
      return {
        ...state,
        nodesConnectedWithJump: payload,
      };
    }
    case ADD_NODES_CONNECTED_JUMP: {
      return {
        ...state,
        nodesConnectedWithJump: merge(
          {},
          state.nodesConnectedWithJump,
          payload
        ),
      };
    }
    case NODES_DELETE_GROUP_CONNECTED_JUMP: {
      let res = { ...state.nodesConnectedWithJump };
      delete res?.[payload?.currentNodeId];
      return {
        ...state,
        nodesConnectedWithJump: { ...res },
      };
    }
    case NODES_UPDATE_CONNECTED_JUMP: {
      let res = { ...state.nodesConnectedWithJump };

      if (!isEmpty(payload?.prevNodeId)) {
        if (includes(res[payload?.prevNodeId], payload?.jumpNodeId)) {
          res = {
            ...res,
            [payload?.prevNodeId]: [...res[payload?.prevNodeId]].filter(
              (i: string) => i !== payload?.jumpNodeId
            ),
          };
        }
      }

      res = {
        ...res,
        [payload?.currentNodeId]: [
          ...(res[payload?.currentNodeId] || []),
          payload?.jumpNodeId,
        ],
      };

      return {
        ...state,
        nodesConnectedWithJump: res,
      };
    }
    case NODES_DELETE_CONNECTED_JUMP: {
      return {
        ...state,
        nodesConnectedWithJump: {
          ...state.nodesConnectedWithJump,
          [payload?.currentNodeId]: [
            ...state.nodesConnectedWithJump[payload?.currentNodeId],
          ].filter((i: string) => i !== payload?.jumpNodeId),
        },
      };
    }
    // NODES_NOTIFIED
    case NODES_NOTIFIED: {
      return {
        ...state,
        notifiedNodes: payload,
      };
    }
    case GET_HTTP_REQUEST_USER_SPACE: {
      return {
        ...state,
        httpRequestUserSpace: payload?.httpRequestUserSpace,
        allVariables: payload?.allVariables,
      };
    }
    case UPDATE_HTTP_REQUEST_USER_SPACE: {
      let resAllVariables = state?.allVariables;
      if (has(payload, "allVariables")) {
        resAllVariables = payload?.allVariables;
      }
      let resHttpRequestUserSpace = state?.httpRequestUserSpace;
      if (has(payload, "httpRequestUserSpace")) {
        resHttpRequestUserSpace = payload?.httpRequestUserSpace;
      }
      return {
        ...state,
        httpRequestUserSpace: resHttpRequestUserSpace,
        allVariables: resAllVariables,
      };
    }
    case UPDATE_SHOW_DRAWER: {
      return {
        ...state,
        showDrawer: payload,
      };
    }
    case UPDATE_SELECTED_CONDITION: {
      return {
        ...state,
        selectedCondition: payload,
      };
    }
    case NODES_FIELDS_ERRORS: {
      return {
        ...state,
        errorsFieldsNodes: payload,
      };
    }
    case ADD_NODES_FIELDS_ERRORS: {
      return {
        ...state,
        errorsFieldsNodes: { ...state.errorsFieldsNodes, ...payload },
      };
    }
    case DELETE_NODES_FIELDS_ERRORS: {
      let errorsFieldsNodes = { ...state.errorsFieldsNodes };
      payload?.forEach((element: string) => {
        delete errorsFieldsNodes[element];
      });
      return {
        ...state,
        errorsFieldsNodes,
      };
    }
    case UPDATE_NODES_FIELDS_ERRORS: {
      let res: any = { ...state.errorsFieldsNodes };
      if (!isEmpty(payload?.typeNode) && payload?.typeNode === "reply") {
        res[payload?.nodeId] = {
          ...res?.[payload?.nodeId],
          [payload?.activeMessageSettings]: {
            ...payload?.data,
          },
        };
        if (
          size(res?.[payload?.nodeId]?.[payload?.activeMessageSettings]) === 0
        ) {
          delete res?.[payload?.nodeId];
        }
        // if(payload?.type==="delete"){
        //   delete res?.[payload?.nodeId]?.[payload?.activeMessageSettings]?.[payload?.attr]
        //   if(size(res?.[payload?.nodeId]?.[payload?.activeMessageSettings])==0){
        //     delete res?.[payload?.nodeId]
        //   }
        // }
        // if(payload?.type==="add"){
        //   res[payload?.nodeId]={
        //     ... res?.[payload?.nodeId],
        //     [payload?.activeMessageSettings]:{
        //       ... res?.[payload?.nodeId]?.[payload?.activeMessageSettings],
        //       [payload?.attr]:payload?.msg
        //     }
        //   }
        // }
      } else {
        if (payload?.type === "delete") {
          delete res?.[payload?.nodeId]?.[payload?.attr];
          if (size(res?.[payload?.nodeId]) === 0) {
            delete res?.[payload?.nodeId];
          }
        }
        if (payload?.type === "add") {
          res[payload?.nodeId] = {
            ...res?.[payload?.nodeId],
            [payload?.attr]: payload?.msg,
          };
        }
      }
      return {
        ...state,
        errorsFieldsNodes: res,
      };
    }
    case UPDATE_NODES_FIELDS_ERRORS_REPLY: {
      let res: any = { ...state.errorsFieldsNodes };
      res[payload?.nodeId] = {
        ...res?.[payload?.nodeId],
        [payload?.activeMessageSettings]: { ...payload?.data },
      };
      if (
        size(res?.[payload?.nodeId]?.[payload?.activeMessageSettings]) === 0
      ) {
        delete res?.[payload?.nodeId];
      }
      return {
        ...state,
        errorsFieldsNodes: res,
      };
    }
    case ADD_FIELDS_ERRORS_REPLY: {
      let res: any = { ...state.errorsFieldsNodes };
      res[payload?.id] = {
        ...(payload?.errorsFields?.[payload?.id] || {}),
      };
      if (size(res[payload?.id]) === 0) {
        delete res?.[payload?.id];
      }
      return {
        ...state,
        errorsFieldsNodes: res,
      };
    }
    case UPDATE_FLOW_OPTIONS: {
      return {
        ...state,
        currentFlowOptions: payload,
      };
    }
    case GET_ORDERED_NODES: {
      return {
        ...state,
        orderedNodesObj: payload,
      };
    }
    case UPDATE_ORDERED_NODES: {
      let errorsRelatedVariable: any = {};
      let nodesIds = keys(state.orderedNodesObj?.[payload?.targetFlowId]) || [];
      let entriesNodes = Object.entries(
        state?.orderedNodesObj?.[payload?.targetFlowId] || {}
      );
      let targetNodeIndex = findIndex(
        nodesIds,
        (nodeId) => nodeId === payload.targetNodeId
      );
      let entriesData = Object.entries(payload?.data || {});
      let resNodes = [
        ...entriesNodes.slice(0, targetNodeIndex + 1),
        ...entriesData,
        ...entriesNodes.slice(targetNodeIndex + 1),
      ];
      let res: any = Object.fromEntries(resNodes);
      map([...(payload?.dataDeletedNodesId || [])], (item) => {
        delete res[item];
      });
      let allVariables = { ...state.allVariables };
      if (payload?.dataDeletedNodesId?.length > 0) {
        mapKeys(allVariables, (_, titleKey: string) => {
          mapKeys(allVariables?.[titleKey], (_, typeKey) => {
            if (
              typeKey === typeVariable.ARRAY ||
              typeKey === typeVariable.OBJECT
            ) {
              mapKeys(allVariables?.[titleKey]?.[typeKey], (_, arrKey) => {
                map([...payload?.dataDeletedNodesId], (item) => {
                  delete allVariables?.[titleKey]?.[typeKey]?.[arrKey]?.[item];
                });
                if (size(allVariables?.[titleKey]?.[typeKey]?.[arrKey]) === 0) {
                  delete allVariables?.[titleKey]?.[typeKey]?.[arrKey];
                }
                if (size(allVariables?.[titleKey]?.[typeKey]) === 0) {
                  delete allVariables?.[titleKey]?.[typeKey];
                }
              });
            } else {
              map([...payload?.dataDeletedNodesId], (item) => {
                delete allVariables?.[titleKey]?.[typeKey]?.[item];
              });
              if (size(allVariables?.[titleKey]?.[typeKey]) === 0) {
                delete allVariables?.[titleKey]?.[typeKey];
              }
            }
          });
          if (size(allVariables?.[titleKey]) === 0) {
            delete allVariables?.[titleKey];
          }
        });
      }
      let newErrorsArr = { ...state.errorsRelatedVariables };
      /** when copying node has connections with variables(node with one branch), should apply processing on variables */
      if (!isEmpty(payload?.copiedNodes)) {
        errorsRelatedVariable = handleErrorsRelatedVaraiblesWithCopyNodes(
          allVariables,
          res,
          payload?.systemVariables,
          payload?.copiedNodes,
          payload?.getVariables,
          payload?.targetFlowId,
          payload.nodesAsObject,
          payload?.userSapce,
          payload?.originalUserSapce,
          state?.orderedNodesObj,
          state?.nodesConnectedWithSubFlows,
          state?.httpRequestUserSpace
        );
      }
      if (!isEmpty(payload?.dataDeletedNodesId)) {
        map(payload?.dataDeletedNodesId, (item) => {
          delete newErrorsArr?.[item];
        });
      }
      let errorsRelatedVariables = {
        ...newErrorsArr,
        ...errorsRelatedVariable,
      };
      return {
        ...state,
        orderedNodesObj: {
          ...state?.orderedNodesObj,
          [payload?.targetFlowId]: res,
        },
        allVariables,
        errorsRelatedVariables,
        isEnableUpdateNodesConnectedWithVars: true,
      };
    }
    case UPDATE_ORDERED_NODES_SUB_FLOWS: {
      return {
        ...state,
        orderedNodesObj: {
          ...state?.orderedNodesObj,
          [payload?.targetFlowId]: payload?.data,
        },
      };
    }
    case CUSTOM_LOADING: {
      return {
        ...state,
        customLodaer: true,
      };
    }
    case CUSTOM_STOP_LOADING: {
      return {
        ...state,
        customLodaer: false,
      };
    }
    case DISPLAY_ERROR_MODAL: {
      return {
        ...state,
        isDisplayErrorModal: true,
      };
    }
    case STOP_DISPLAY_ERROR_MODAL: {
      return {
        ...state,
        isDisplayErrorModal: false,
      };
    }
    case UPDATE_ALL_VARIABLES: {
      let newAllVars = { ...state.allVariables };
      mapKeys(payload, (variable, variableKey) => {
        mapKeys(variable, (itemType, itemTypeKey) => {
          if (
            includes([typeVariable.ARRAY, typeVariable.OBJECT], itemTypeKey)
          ) {
            mapKeys(itemType, (itemArr, itemArrKeys) => {
              newAllVars = {
                ...newAllVars,
                [variableKey]: {
                  ...(newAllVars?.[variableKey] || {}),
                  [itemTypeKey]: {
                    ...(newAllVars?.[variableKey]?.[itemTypeKey] || {}),
                    [itemArrKeys]: {
                      ...(newAllVars?.[variableKey]?.[itemTypeKey]?.[
                        itemArrKeys
                      ] || {}),
                      ...itemArr,
                    },
                  },
                },
              };
            });
          } else {
            newAllVars = {
              ...newAllVars,
              [variableKey]: {
                ...(newAllVars?.[variableKey] || {}),
                [itemTypeKey]: {
                  ...(newAllVars?.[variableKey]?.[itemTypeKey] || {}),
                  ...itemType,
                },
              },
            };
          }
        });
      });
      return {
        ...state,
        allVariables: { ...newAllVars },
      };
    }
    case ADD_HTTP_REQUEST_USER_SPACE: {
      return {
        ...state,
        httpRequestUserSpace: {
          ...state.httpRequestUserSpace,
          [payload?.id]: {
            ...payload?.data,
          },
        },
      };
    }
    case NODES_CONNECTED_SUB_FLOW: {
      return {
        ...state,
        nodesConnectedWithSubFlows: payload,
      };
    }

    case ADD_NODES_CONNECTED_SUB_FLOW: {
      return {
        ...state,
        nodesConnectedWithSubFlows: mergeWith(
          state.nodesConnectedWithSubFlows,
          payload,
          mergeCustomizer
        ),
      };
    }

    case UPDATE_NODES_CONNECTED_SUB_FLOW: {
      let resNodesConnectedWithSubFlows: {
        [subFlowId: string]: subFlowsUsageData;
      } = { ...state.nodesConnectedWithSubFlows };
      let resTargetFlowCallee = [
        ...(resNodesConnectedWithSubFlows[payload?.targetFlowId]?.callee || []),
      ];
      let resTargetFlowNodes = {
        ...(resNodesConnectedWithSubFlows[payload?.targetFlowId]?.nodes || {}),
      };
      let resCurrentCalledFlow: { [subFlowId: string]: subFlowsUsageData } = {};
      let resPrevCalledFlow: { [subFlowId: string]: subFlowsUsageData } = {};
      if (!isEmpty(payload?.prevCalledFlow)) {
        //should delete targetFlowId from resPrevCalledFlow's caller, if the targetFlowId contain one node connectes with resPrevCalledFlow
        if (
          Object.values(resTargetFlowNodes || []).filter(
            (item) => item === payload?.prevCalledFlow?.id
          ).length === 1
        ) {
          resPrevCalledFlow = {
            [payload?.prevCalledFlow?.id]: {
              ...resNodesConnectedWithSubFlows[payload?.prevCalledFlow?.id],
            },
          };
          let resCaller = filter(
            [
              ...(resNodesConnectedWithSubFlows[payload?.prevCalledFlow?.id]
                ?.caller || []),
            ],
            (i) => i !== payload?.targetFlowId
          );
          resTargetFlowCallee = filter(
            resTargetFlowCallee,
            (i) => i !== payload.prevCalledFlow?.id
          );

          resPrevCalledFlow = {
            [payload?.prevCalledFlow?.id]: {
              ...resPrevCalledFlow?.[payload?.prevCalledFlow?.id],
              caller: [...resCaller],
            },
          };
        }
        if (isEmpty(payload?.currentCalledFlow))
          delete resTargetFlowNodes[payload?.targetNodeId];
      }
      if (!isEmpty(payload?.currentCalledFlow)) {
        resCurrentCalledFlow = {
          [payload?.currentCalledFlow?.id]: {
            ...(resNodesConnectedWithSubFlows?.[
              payload?.currentCalledFlow?.id
            ] || {}),
          },
        };
        resTargetFlowCallee.push(payload?.currentCalledFlow?.id);
        resTargetFlowNodes[payload?.targetNodeId] =
          payload?.currentCalledFlow?.id;
        resCurrentCalledFlow = {
          [payload?.currentCalledFlow?.id]: {
            ...(resCurrentCalledFlow?.[payload?.currentCalledFlow?.id] || {}),
            caller: uniq([
              ...(resCurrentCalledFlow?.[payload?.currentCalledFlow?.id]
                ?.caller || []),
              payload?.targetFlowId,
            ]),
          },
        };
      }
      resNodesConnectedWithSubFlows = {
        ...resNodesConnectedWithSubFlows,
        [payload?.targetFlowId]: {
          ...resNodesConnectedWithSubFlows?.[payload?.targetFlowId],
          nodes: { ...resTargetFlowNodes },
          callee: [...uniq(resTargetFlowCallee)],
        },
        ...resPrevCalledFlow,
        ...resCurrentCalledFlow,
      };
      return {
        ...state,
        nodesConnectedWithSubFlows: { ...resNodesConnectedWithSubFlows },
        customLodaer: true,
      };
    }
    case DELETE_NODES_CONNECTED_SUB_FLOW: {
      let resNodesConnectedWithSubFlows: {
        [subFlowId: string]: subFlowsUsageData;
      } = { ...state.nodesConnectedWithSubFlows };
      map(payload?.dataDeletedNodesId, (subFlowNodeId: string) => {
        let targetFlowNodes = {
          ...(resNodesConnectedWithSubFlows?.[payload?.targetFlowId]?.nodes ||
            {}),
        };
        let targetFlowCallee = [
          ...(resNodesConnectedWithSubFlows?.[payload?.targetFlowId]?.callee ||
            []),
        ];

        if (Object.hasOwn(targetFlowNodes, subFlowNodeId)) {
          let subFlowValue: string = targetFlowNodes?.[subFlowNodeId] || "";
          delete targetFlowNodes?.[subFlowNodeId];
          if (!includes(Object.values(targetFlowNodes || []), subFlowValue)) {
            // console.log("targetFlowNodes doen't have any node connected with subFlowValue, should remove subFlowValue from callee")
            // console.log("should remove targetFlowId from caller found in subFlowValue")
            targetFlowCallee = filter(
              resNodesConnectedWithSubFlows?.[payload?.targetFlowId]?.callee ||
              [],
              (item) => item !== subFlowValue
            );

            resNodesConnectedWithSubFlows = {
              ...resNodesConnectedWithSubFlows,
              [subFlowValue]: {
                ...resNodesConnectedWithSubFlows?.[subFlowValue],
                caller: filter(
                  [
                    ...(resNodesConnectedWithSubFlows?.[subFlowValue]?.caller ||
                      []),
                  ],
                  (item) => item !== payload?.targetFlowId
                ),
              },
            };
          }
          resNodesConnectedWithSubFlows = {
            ...resNodesConnectedWithSubFlows,
            [payload?.targetFlowId]: {
              ...resNodesConnectedWithSubFlows?.[payload?.targetFlowId],
              nodes: targetFlowNodes,
              callee: targetFlowCallee,
            },
          };
        }
      });
      return {
        ...state,
        nodesConnectedWithSubFlows: resNodesConnectedWithSubFlows,
      };
    }

    case DISPLAY_TEMPLATE_MODAL: {
      return {
        ...state,
        isDisplayTemplateModal: true,
      };
    }
    case STOP_DISPLAY_TEMPLATE_MODAL: {
      return {
        ...state,
        isDisplayTemplateModal: false,
      };
    }
    default:
      return state;
  }
}
