import { includes, isArray, isEmpty, mapKeys, map, has, keys, difference, pick } from "lodash";
import { Node } from "react-flow-renderer";
import * as types from "components/flow/flow-editor/tabPanelFlowEditor/NodeTypes";
import { isNumericRegex } from "utils/helper";
import { typeVariable } from "enums/flow";

export const mergeCustomizer = (objValue: any, srcValue: any) => {
  if (isArray(objValue)) {
    return objValue.concat(srcValue);
  }
};

export const handleErrorsRelatedVaraiblesWithCopyNodes = (
  allVariables: any,
  nodes: any,
  systemVariables: any,
  copiedNodes: any,
  getVariables: any,
  targetFlowId: any,
  nodesAsObject: any,
  userSapce: any = {},
  originalUserSapce: any = {},
  orderedNodesObj: any,
  nodesConnectedWithSubFlows: any,
  httpRequestUserSpace: any
) => {
  let reultsCorrectVar: any = {};
  let errorsRelatedVariable: any = {}
  copiedNodes.forEach((copiedNode: Node) => {
    const { data, type, id } = copiedNode as Node
    if (includes([types.CONDITION, types.FILTER], type)) {
      let conditionsRules = data?.conditionsRules || null;
      if (conditionsRules && !isEmpty(conditionsRules)) {
        mapKeys(conditionsRules, (_, key) => {
          let correctVariables: any = [];
          let nodeId: string =
            type === types.CONDITION
              ? nodes?.[id]?.parentId || ""
              : id;
          if (!isEmpty(reultsCorrectVar?.[nodeId])) {
            correctVariables = reultsCorrectVar[nodeId];
          } else {
            correctVariables = getVariables(
              true,
              nodeId,
              { ...orderedNodesObj, [targetFlowId]: nodes },
              nodesAsObject,
              nodesConnectedWithSubFlows,
              httpRequestUserSpace,
              { ...originalUserSapce, ...userSapce },
              true,
              type === types.FILTER
            );
            reultsCorrectVar[nodeId] = correctVariables;
          }
          map(conditionsRules[key]?.rules, (rule) => {
            let isNotFound = false;

            if (!has(allVariables, rule?.key?.title)) {
              isNotFound = true;
            } else {
              let keyType = rule?.key?.type || typeVariable.STRING;
              if (
                !/"/.test(rule?.value) &&
                isNumericRegex(rule?.value)
              ) {
                keyType = "number";
              }

              if (
                isEmpty(
                  correctVariables.find(
                    (i: any) =>
                      i?.title === rule?.key?.title &&
                      i?.type === keyType
                  )
                )
              ) {
                isNotFound = true;
              }
            }
            if (isNotFound && !isEmpty(rule?.key)) {
              if (!isEmpty(id)) {
                errorsRelatedVariable = {
                  ...errorsRelatedVariable || {},
                  [id]: {
                    rulesId: [
                      ...(errorsRelatedVariable?.[id || ""]?.rulesId ||
                        []),
                      rule?.id,
                    ],
                    reason: {
                      [rule?.key?.title || ""]: rule?.key?.type || "",
                    },
                  },
                };
              }
            }
          })
        })
      }

    }
    if (includes([types.FILTER, types.MAP, types.SORT, types.GROUP_BY], type)) {
      if (has(data, "sourceArray") && data?.sourceArray?.relatedBy) {
        let isNotFound = false;
        if (!has(allVariables, data?.sourceArray?.title)) {
          isNotFound = true;
        } else {
          let correctVariables = getVariables(
            false,
            id,
            { ...orderedNodesObj, [targetFlowId]: nodes },
            nodesAsObject,
            nodesConnectedWithSubFlows,
            httpRequestUserSpace,
            { ...originalUserSapce, ...userSapce },
            undefined,
            undefined,
            type
          );
          if (
            isEmpty(
              correctVariables.find(
                (i: any) =>
                  i?.title === data?.sourceArray?.title &&
                  i?.type === data?.sourceArray?.type &&
                  JSON.stringify(i?.arrKeys) ===
                  JSON.stringify(data?.sourceArray?.arrKeys)
              )
            )
          ) {
            isNotFound = true;
          }

        }
        if (isNotFound) {
          errorsRelatedVariable = {
            ...errorsRelatedVariable,
            [id]: {
              rulesId: [
                ...(errorsRelatedVariable?.[id]?.rulesId || []),
                id,
              ],
              reason: {
                [data?.sourceArray?.title]: {
                  [data?.sourceArray?.type]: JSON.stringify(
                    data?.sourceArray?.arrKeys
                  ),
                },
              },
            },
          };
        }
      }
    }

    if (type === types.REPORT) {
      let cells = data?.cells || [];
      if (cells?.length > 0) {
        let correctVariables: any = [];
        if (!isEmpty(reultsCorrectVar?.[id])) {
          correctVariables = reultsCorrectVar[id];
        } else {
          correctVariables = getVariables(
            true,
            id,
            { ...orderedNodesObj, [targetFlowId]: nodes },
            nodesAsObject,
            nodesConnectedWithSubFlows,
            httpRequestUserSpace,
            { ...originalUserSapce, ...userSapce },
            true,
            false
          );
          reultsCorrectVar[id] = correctVariables;
        }
        map(cells, (cell) => {
          let isNotFound = false;
          if (isEmpty(systemVariables?.[cell?.systemVar?.title])) {
            if (!has(allVariables, cell?.systemVar?.title)) {
              isNotFound = true;
            } else {
              let keyType = cell?.systemVar?.type || typeVariable.STRING;
              if (
                isEmpty(
                  correctVariables.find(
                    (i: any) =>
                      i?.title === cell?.systemVar?.title &&
                      i?.type === keyType
                  )
                )
              ) {
                isNotFound = true;
              }
            }
          }
          if (isNotFound && !isEmpty(cell?.systemVar)) {
            if (!isEmpty(id)) {
              errorsRelatedVariable = {
                ...(errorsRelatedVariable || {}),
                [id]: {
                  rulesId: [
                    ...(errorsRelatedVariable?.[id || ""]?.rulesId || []),
                    cell?.id,
                  ],
                  reason: {
                    [cell?.systemVar?.title || ""]:
                      cell?.systemVar?.type || "",
                  },
                },
              };
            }
          }
        });
      }
    }
  })
  return errorsRelatedVariable
}

export const findDifferences = (obj1: any = {}, obj2: any = {}) => {
  // Step 1: Get keys from both objects
  const keys1 = keys(obj1);
  const keys2 = keys(obj2);

  // Step 2: Find keys that are in obj2 but not in obj1
  const extraKeys = difference(keys1, keys2);
  // Step 3: Pick these keys from obj2 to create the result
  return pick(obj1, extraKeys);
};