import { IntialStateReports } from "./type";
import { GET_REPORTS, GET_REPORT, DELETE_REPORT } from "./types";
import { filter } from "lodash";

const initialState: IntialStateReports = {
  reports: [],
  currentReport: null
};

export default function reportsReducer(
  state = initialState,
  action: { type: string; payload: any }
) {
  const { type, payload } = action;

  switch (type) {
    case GET_REPORTS: {
      return {
        ...state,
        reports: payload
      };
    }
    case GET_REPORT: {
      return {
        ...state,
        currentReport: payload
      };
    }
    case DELETE_REPORT: {
      return {
        ...state,
        reports: filter(state?.reports, (item: any) => item?.ReportKey!== payload)
      };
    }
    default:
      return state;
  }
}
