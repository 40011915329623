import { createSelector } from "reselect";
import { SubscribeIntialState } from "./type";

interface RootState {
  subscribe: SubscribeIntialState;
}

// This used to memorize the selector value
export const subscribeSelector = createSelector(
  (state: RootState) => state.subscribe,
  (subscribe: SubscribeIntialState) => {
    return {
      plans: subscribe.plans,
      subscribedPlan: subscribe.subscribedPlan,
      cards: subscribe.cards,
      walletBalance: subscribe.walletBalance,
      balanceType: subscribe.balanceType,
      transactions: subscribe.transactions,
      walletConfig: subscribe.walletConfig,
    };
  }
);
