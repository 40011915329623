import { useSwitch } from "@mui/base/SwitchUnstyled";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Divider, IconButton, Menu, MenuItem } from "@mui/material";
import { styled } from "@mui/system";
import { AppContext } from "App";
import clsx from "clsx";
import { AppConfig } from "enums/config";
import React, { useContext, useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { updatingProfileStatus } from "redux/auth/actions";
import { authSelector } from "redux/auth/selector";
import { companySelector } from "redux/company/selector";

const SwitchRoot = styled("div")(
  ({ theme }) => ` 
  position: relative;
  padding: 0x;
  background-color: ${theme.palette.general.white};
  border:1px solid ${theme.palette.general.grayDark};
  border-radius: ${AppConfig.BORDER_RADIUS_MIN};
  width: 80%;
  height: 100%;
  display: block;
`
);

const SwitchInput = styled("input")`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  z-index: 1;
  margin: 0;
  cursor: pointer;
`;

const SwitchThumb = styled("span")(
  ({ theme }) => `
  position: absolute;
  display: flex;
  border-radius: ${AppConfig.BORDER_RADIUS_MIN};
  top: 4.5px;
  left: 12px;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1);
  line-height:1.6;
  padding-top: 0px;
  
  &:before {
    display: block;
    content: "${theme.direction === "ltr" ? "Online" : "متاح"}";
    font-family: 'IBM Plex Sans Arabic';
    background-color: ${theme.palette.success.main};
    width: 100%;
    height: 100%;
    text-align: center;
    border-radius: ${AppConfig.BORDER_RADIUS_MIN};
    color:${theme.palette.general.offWhite};
    margin-left:-7px;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
  }
  &:after {
    display: block;
    content: "${theme.direction === "ltr" ? "Offline" : "غير متاح"}";
    font-family: 'IBM Plex Sans Arabic';
    width: 100%;
    height: 100%;
    text-align: center;
    color:${theme.palette.general.grayDark};
  }

  &.focusVisible {
    background-color: #79B;
  }

  &.checked {
    transform: translateX(1em);
    &:before {
      display: block;
      content: "${theme.direction === "ltr" ? "Online" : "متاح"}";
      font-family: 'IBM Plex Sans Arabic';
      width: 100%;
      height: 100%;
      text-align: center;
      color:${theme.palette.general.grayDark};
      background-color:transparent;
      border-radius: ${AppConfig.BORDER_RADIUS_MIN};
    }
    &:after {
        display: block;
        content: "${theme.direction === "ltr" ? "Offline" : "غير متاح"}";
        font-family: 'IBM Plex Sans Arabic';
        background-color: ${theme.palette.error.main};
        border-radius: ${AppConfig.BORDER_RADIUS_MIN};
        width: 100%;
        height: 100%;
        text-align: center;
        box-shadow: none;
        color:${theme.palette.general.offWhite};
        box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
        margin-right:-9.5px;
    }
  }
`
);

const SwitchTrack = styled("span")(
  ({ theme }) => `
  
`
);

interface Props {}

const CustomSwitch: React.FC<Props & any> = (props) => {
  const [t, , minPcMatch] = useContext(AppContext);
  const { getInputProps, checked, disabled, focusVisible } = useSwitch(props);
  const stateClasses = {
    checked,
    disabled,
    focusVisible,
  };

  const onlineCustomStatus = useSelector(
    (state) => companySelector(state).onlineCustomStatus,
    shallowEqual
  );
  const offlineCustomStatus = useSelector(
    (state) => companySelector(state).offlineCustomStatus,
    shallowEqual
  );

  const [availableCustomStatus, setAvailableCustomStatus] = useState<Boolean>(
    onlineCustomStatus?.length > 0 && offlineCustomStatus?.length > 0
  );

  useEffect(() => {
    if (onlineCustomStatus?.length > 0 && offlineCustomStatus?.length > 0) {
      setAvailableCustomStatus(true);
    } else {
      setAvailableCustomStatus(false);
    }
  }, [onlineCustomStatus, offlineCustomStatus]);

  const customStatus = useSelector((state) => authSelector(state).customStatus);

  const open = Boolean(props.anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    props.setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    props.setAnchorEl(null);
  };

  const dispatch = useDispatch();

  const handleClickStatus = (item: any) => {
    props.setUserVisibility(item.type === 1 ? false : true);
    dispatch(updatingProfileStatus(item.type === 1 ? true : false, item.id));
    props.setAnchorEl(null);
  };

  return (
    <SwitchRoot
      sx={{
        width: availableCustomStatus ? "11.5em" : "9em",
        height: "34px",
        mr: minPcMatch ? "1em" : 0,
      }}
    >
      <SwitchTrack>
        <SwitchThumb
          className={clsx(stateClasses)}
          sx={{
            width: "7.5em",
            height: "25px",
            borderRadius: "12px",
            left: checked ? "-7px" : "12px",
            "&:before": {
              boxShadow: 0,
            },
          }}
        />
      </SwitchTrack>
      <SwitchInput {...getInputProps()} aria-label="Online Switch" />

      {availableCustomStatus ? (
        <>
          <IconButton
            sx={{
              position: "absolute",
              right: "0",
              top: "0",
              height: "100%",
              borderRadius: "0 12px 12px 0",
              padding: "0.5em",
              zIndex: 2,
            }}
            onClick={handleClick}
          >
            <KeyboardArrowDownIcon />
          </IconButton>
          <Menu
            id="lang-menu"
            MenuListProps={{
              "aria-labelledby": "lang-button",
            }}
            slotProps={{
              paper: {
                sx: {
                  width: "10em",
                  borderRadius: "0 0 12px 12px",
                  boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.25)",
                },
              },
            }}
            anchorReference={"anchorEl"}
            anchorEl={props.anchorEl}
            open={open}
            onClose={handleClose}
          >
            <MenuItem disabled disableRipple key={"Online Status"}>
              {t("online_status")}
            </MenuItem>
            {onlineCustomStatus.map((item: any) => (
              <MenuItem
                dense
                onClick={() => {
                  handleClickStatus(item);
                }}
                disableRipple
                key={item?.id}
                selected={item?.id === customStatus}
              >
                {item?.status}
              </MenuItem>
            ))}
            <Divider />
            <MenuItem disabled disableRipple key={"Offline Status"}>
              {t("offline_status")}
            </MenuItem>
            {offlineCustomStatus.map((item: any) => (
              <MenuItem
                dense
                onClick={() => {
                  handleClickStatus(item);
                }}
                disableRipple
                key={item?.id}
                selected={item?.id === customStatus}
              >
                {item?.status}
              </MenuItem>
            ))}
          </Menu>
        </>
      ) : null}
    </SwitchRoot>
  );
};

export default CustomSwitch;
