export enum Routes {
  ROOT_ROUTE = "/",
  RESET_PASSWORD = "/:id",
  OVERVIEW = "/overview",
  COMPLETE_REGISTER = "/completeRegister",
  DASHBOARD = "/dashboard",
  CHAT = "/chat",
  REQUEST = "/request",
  COMMUNITY = "/community",
  CAMPAIGN = "/campaign",
  SURVEY = "/survey",
  FLOW = "/flow",
  FLOW_MY_FLOWS = "/flow/my_flows",
  FLOW_TEMPLATES = "/flow/templates",
  FLOW_TEMPLATE = "/flow/template",
  FLOW_EDITOR = "/flow/editor",
  FLOW_INTEGRATIONS="/flow/integrations",
  FLOW_PLUGIN_INTEGRATIONS="/flow/integrations/plugin",
  SETTING = "/setting",
  SETTING_MY_ACCOUNT = "/setting/my_account",
  SETTING_PREDEFINE = "/setting/predefine",
  SETTING_TEMPLATE_MESSAGES = "/setting/template_messages",
  SETTING_MANAGE_AGENT = "/setting/manage_agent",
  SETTING_CHANNELS = "/setting/channels",
  SETTING_MANAGE_GROUP = "/setting/manage_group",
  SETTING_KPI = "/setting/kpi",
  SETTING_TAGS = "/setting/tags",
  SETTING_COMPANY = "/setting/company",
  SETTING_REQUEST = "/setting/request",
  SETTING_NOTIFICATIONS="/setting/notifications",
  QUESTION_AND_ANSWER = "/question-and-answer",
  QUESTION_AND_ANSWER_KNOWLEDGE_BASE = "/question-and-answer/knowledge_base",
  QUESTION_AND_ANSWER_CUSTOMER_CARE = "/question-and-answer/customer_care",
  QUESTION_AND_ANSWER_IT = "/question-and-answer/IT",
  QUESTION_AND_ANSWER_APPROVALS = "/question-and-answer/approvals",
  QUESTION_AND_ANSWER_HR = "/question-and-answer/HR",
  API_OAUTH = "/api/oauth",
  REDIRECT_CHANNELS = "/redirect/channels",
  REPORTS = "/reports",
  REPORTS_MY_REPORTS = "/reports/my_reports",
  REPORTS_REPORT = "/reports/report",
  REPORTS_TEMPLATES = "/reports/templates",
  REPORTS_TEMPLATES_SHEETS = "/reports/templates/sheets",
  REPORT = "/reports/report",
  SUBSCRIPTION = "/subscription",
  SUBSCRIPTION_PLANS = "/subscription/plans",
  SUBSCRIPTION_PAYMENT = "/subscription/payment",
  SUBSCRIPTION_BILLING = "/subscription/billing",
  SUBSCRIPTION_WALLET = "/subscription/wallet",
  SUBSCRIPTION_TRANSACTION = "/subscription/transaction",
}

export const MAIN_ROUTES = [
  Routes.DASHBOARD,
  Routes.CHAT,
  Routes.COMMUNITY,
  Routes.QUESTION_AND_ANSWER,
  Routes.CAMPAIGN,
  Routes.SETTING,
  Routes.FLOW,
  Routes.REPORTS,
  Routes.SUBSCRIPTION,
  Routes.REQUEST,
  Routes.SURVEY,
];

export const SETTING_ROUTES = [
  //Routes.SETTING,
  Routes.SETTING_MY_ACCOUNT,
  Routes.SETTING_PREDEFINE,
  Routes.SETTING_TEMPLATE_MESSAGES,
  Routes.SETTING_MANAGE_GROUP,
  Routes.SETTING_MANAGE_AGENT,
  Routes.SETTING_CHANNELS,
  Routes.SETTING_TAGS,
  Routes.SETTING_COMPANY,
  Routes.SETTING_KPI,
  Routes.SETTING_REQUEST,
  Routes.SETTING_NOTIFICATIONS
];

export const COMMUNITY_ROUTES = [
  Routes.QUESTION_AND_ANSWER,
  Routes.QUESTION_AND_ANSWER_KNOWLEDGE_BASE,
  Routes.QUESTION_AND_ANSWER_CUSTOMER_CARE,
  Routes.QUESTION_AND_ANSWER_IT,
  Routes.QUESTION_AND_ANSWER_APPROVALS,
  Routes.QUESTION_AND_ANSWER_HR,
];

export const FLOW_ROUTES = [
  Routes.FLOW,
  Routes.FLOW_MY_FLOWS,
  Routes.FLOW_TEMPLATES,
  Routes.FLOW_TEMPLATE,
  Routes.FLOW_EDITOR,
  Routes.FLOW_INTEGRATIONS,
  Routes.FLOW_PLUGIN_INTEGRATIONS
];
export const REPORTS_ROUTES = [
  Routes.REPORTS,
  Routes.REPORTS_MY_REPORTS,
  Routes.REPORTS_TEMPLATES,
  Routes.REPORTS_REPORT,
  Routes.REPORTS_TEMPLATES_SHEETS,
];
export const SUBSCRIPTION_ROUTES = [
  Routes.SUBSCRIPTION,
  Routes.SUBSCRIPTION_PLANS,
  Routes.SUBSCRIPTION_PAYMENT,
  Routes.SUBSCRIPTION_TRANSACTION,
  Routes.SUBSCRIPTION_WALLET,
  Routes.SUBSCRIPTION_BILLING,
];
